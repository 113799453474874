@import './media';

.a-disabled {
    pointer-events: none;
}

.h-none {
    display: none;
}

.h-contents {
    display: contents;
}

.h-text-normal {
    font-weight: normal;
}

.h-relative {
    position: relative;
}

.h-text-bold {
    font-weight: 600;
}

.h-text-bold-primary {
    color: #20836D;
    font-weight: 600;
}

.h-text-bold-error {
    color: #EB0038;
    font-weight: 600;
}

.h-text-light {
    font-weight: 300;
}

.h-va-top {
    vertical-align: top !important;
}

.h-va-center {
    vertical-align: middle !important;
}

.h-va-bottom {
    vertical-align: bottom !important;
}

.h-ta-left {
    text-align: left !important;
}

.h-ta-right {
    text-align: right !important;
}

.h-ta-center {
    text-align: center !important;
}

.h-td-none {
    text-decoration: none;
}

.h-cu-default {
    cursor: default;
}

.h-cu-pointer {
    cursor: pointer;
}

.h-width--full {
    width: 100%;
}

.h-height--full {
    height: 100%;
}

.h-height--fit {
    height: fit-content;
}

.h-max-width--350 {
    margin: 0 auto;
    max-width: 350px;
}

.h-max-width--400 {
    margin: 0 auto;
    max-width: 400px;
}

.h-width--min {
    width: min-content;
}

.h-width--fit {
    width: fit-content;
}

.h-width--max {
    width: max-content;
}

@include media-mobile {
    .h-ta-center--mobile {
        text-align: center !important;
    }

    .h-ta-left--mobile {
        text-align: left !important;
    }
}

.h-mt-0 {
    margin-top: 0;
}

.h-mt-10 {
    margin-top: 10px;
}

.h-mt-20 {
    margin-top: 20px !important;
}

.h-mt-30 {
    margin-top: 30px !important;
}

.h-mb-30 {
    margin-bottom: 30px !important;
}

.h-mb-20 {
    margin-bottom: 20px !important;
}

.h-mb-10 {
    margin-bottom: 10px !important;
}

.h-mb-0 {
    margin-bottom: 0 !important;
}

.h-ml-10 {
    margin-left: 10px !important;
}

.h-ml-20 {
    margin-left: 20px !important;
}

.h-mr-0 {
    margin-right: 0 !important;
}

.h-mr-20 {
    margin-right: 20px !important;
}

.h-mr-10 {
    margin-right: 10px !important;
}

.h-pv-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.h-pv-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.h-pv-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.h-pv-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}

.h-ph-none {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.h-ph-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.h-ph-30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.h-pl-10 {
    padding-left: 10px !important;
}

.h-pl-20 {
    padding-left: 20px !important;
}

.h-pt-0 {
    padding-top: 0 !important;
}

.h-pb-10 {
    padding-bottom: 10px !important;
}

.h-pb-30 {
    padding-bottom: 30px !important;
}

.h-pb-none {
    padding-bottom: 0 !important;
}

.h-p-none {
    padding: 0 !important;
}

.h-ws-nowrap {
    white-space: nowrap !important;
}

.h-ws-normal {
    white-space: normal !important;
}

.h-ofit-none {
    object-fit: none !important;
}

.h-opacity--1 {
    opacity: 1.0 !important;
}

@include media-mobile {
    .h-ta-center--mobile {
        text-align: center !important;
    }
}

@include media-tablet {
    .h-ta-center--tablet {
        text-align: center !important;
    }
}


.m-left-auto {
    margin-right: auto;
    margin-left: 0;
}

.m-right-auto {
    margin-right: 0;
    margin-left: auto;
}

.h-overflow-hidden {
    overflow: hidden;
}

.h-hidden-table-cell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.d-flex {
    display: flex;

    &--gap-40 {
        gap: 40px;
    }

    &--gap-20 {
        gap: 20px;
    }

    &--gap-row-20 {
        row-gap: 20px;
    }

    &--gap-10 {
        gap: 10px;
    }

    &--gap-5 {
        gap: 5px;
    }

    &--wrap {
        flex-wrap: wrap;
    }

    &--1 {
        flex: 1;
    }

    &--row {
        flex-direction: row;
    }

    &--column {
        flex-direction: column;
    }

    &--center {
        justify-content: center;
    }

    &--between {
        justify-content: space-between;
    }

    &--right {
        justify-content: right;
    }

    &--v-start {
        align-items: flex-start;
    }

    &--v-end {
        align-items: flex-end;
    }

    &--v-center {
        align-items: center;
    }

    &--v-stretch {
        align-items: stretch;
    }

    &--v-baseline {
        align-items: baseline;
    }
}

.h-grid {
    display: grid;

    &--colum-2 {
        grid-template-columns: repeat(2, 1fr);
    }

    &--colum-3 {
        grid-template-columns: repeat(3, 1fr);
    }

    &--gap-30 {
        gap: 30px;
    }

    &--column-gap-30 {
        column-gap: 30px;
    }

    &--row-gap-30 {
        row-gap: 30px;
    }

    @include media-tablet {
        &--colum-2 {
            grid-template-columns: 1fr;
        }
    }
}
